<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md10 lg10>
        <v-layout wrap justify-center py-5 pa-2 class="hidden-sm-and-down">
          <v-flex xs12 sm4 md4 style="background-color: #9aa32f" pb-5>
            <v-img :src="require('./../../assets/images/Gau.png')" contain height="300px"><template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                </v-row> </template></v-img>
            <span style="color: black; font-weight: bold; font-size: 22px" class="poppinsbold">WHERE?</span><br />
            <v-layout wrap justify-center pt-4 pb-9>
              <v-flex xs12 sm7 md9>
                <span style="color: black; font-size: 20px" class="poppinsregular">Guindy Children’s Park, 
                </span>
                <br />
                <span style="color: black; font-size: 16px" class="poppinsregular">Guindy, Chennai, </span>
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">
                  Tamil Nadu 600022</span>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-5>
              <v-flex xs12>
                <span style="color: black; font-weight: bold; font-size: 22px" class="poppinsbold">WHEN?</span><br />
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">7th-8th Feb 2025, 10 am onwards</span>
              </v-flex>
            </v-layout>
            <br />
            <v-layout wrap justify-center pt-4>
              <v-flex xs12>
                <span style="color: black; font-weight: bold; font-size: 20px" class="poppinsbold">QUESTIONS?</span><br />
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">Call on
                  <b> <span class="poppinsbold">+91 7069074843</span> </b></span>
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">Email at</span>
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">
                  <b>
                    <span class="poppinsbold">saymanti@wti.org.in</span></b></span>
              </v-flex>
              <v-flex xs12 pt-5>
                <v-btn color="black" dark href="Agenda.pdf" target="_blank"><span
                    style="text-transform: none; font-family: poppinsmedium; font-size: 14px; color:white">View Agenda</span></v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm8 md8 text-center>
            <v-layout wrap justify-center>
              <v-flex xs12 sm8 text-center>
                <v-img :src="require('./../../assets/images/GajTamilcopy.png')" contain><template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                    </v-row> </template></v-img>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pa-5>
              <v-flex xs12 sm10 text-left pt-5>
                <span style="color: black; font-size: 16px" class="poppinsregular">
                  This year, the Wildlife Trust of India's Gaj Utsav Campaign
                  Event in Chennai, Tamil Nadu as Yannai Thiruvizha, a celebration of
                  India's National Heritage Animal.
                  <br />
                  <br />
                  The event, organised in partnership with the Govt. of Tamil
                  Nadu, aims to raise awareness about Asian elephants and
                  advocate for the
                  <b>#RightofPassage</b> of elephants in the state. It will
                  bring together policymakers, media, infrastructure agencies,
                  legal professionals, and wildlife experts to address
                  conservation challenges and sustainable development models.
                  <!-- <br />
                  <br /> -->
                  <!-- Organised parallel to the Kochi Muziris Biennale, Aa Aana is a
                  month-long art exhibition under Gajotsavam, raising awareness
                  about Elephants through art, culture, dance, music, film,
                  literature, media, ecology and policy.
                  <a href="data.pdf" download="Aana_Handbook_Schedule"
                    ><span style="color: #03a5fc">
                      <u> <b> Event Schedule</b></u></span
                    >
                  </a> -->
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center py-5 class="hidden-md-and-up">
          <v-flex xs12 sm12 md8 text-center>
            <v-layout wrap justify-center>
              <v-flex xs12 sm8 text-center>
                <v-img :src="require('./../../assets/images/GajTamilcopy.png')" contain><template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                    </v-row> </template></v-img>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pa-5>
              <v-flex xs12 sm10 text-center pt-5>
                <span style="color: black; font-size: 16px" class="poppinsregular">
                  This year, the Wildlife Trust of India's Gaj Utsav Campaign
                  Event in Chennai, Tamil Nadu as Yannai Thiruvizha, a celebration of
                  India's National Heritage Animal.
                  <br />
                  The event, organised in partnership with the Govt. of Tamil
                  Nadu, aims to raise awareness about Asian elephants and
                  advocate for the
                  <b>#RightofPassage </b> of elephants in the state. It will
                  bring together policymakers, media, infrastructure agencies,
                  legal professionals, and wildlife experts to address
                  conservation challenges and sustainable development models.
                  <!-- Gajotsavam, is a celebration of the Asian Elephant as India’s
                  National Heritage Animal in God’s own country, Kerala.
                  <br />
                  Organised parallel to the Kochi Muziris Biennale, Aa Aana is a
                  month-long art exhibition under Gajotsavam, raising awareness
                  about Elephants through art, culture, dance, music, film,
                  literature, media, ecology and policy. -->
                </span>
                <!-- <br />
                <br />
                <span>
                  <a href="data.pdf" download="Aana_Handbook_Schedule">
                    <span style="color: #03a5fc">
                      <b><u>Event Schedule</u></b></span
                    >
                  </a>
                </span> -->
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs11 sm11 md4 style="background-color: #9aa32f" pb-5>
            <v-img :src="require('./../../assets/images/Gau.png')" contain height="200px"><template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                </v-row> </template></v-img>
            <span style="color: black; font-weight: bold; font-size: 20px" class="poppinsbold">WHERE?</span><br />
            <v-layout wrap justify-center pt-4 pb-9>
              <v-flex xs8 sm7 md8>
                <span style="color: black; font-size: 20px" class="poppinsregular">Guindy Children’s Park, 
                </span>
                <br />
                <span style="color: black; font-size: 16px" class="poppinsregular">Guindy, Chennai, </span>
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">
                  Tamil Nadu 600022</span>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-4>
              <v-flex xs12>
                <span style="color: black; font-weight: bold; font-size: 20px" class="poppinsbold">WHEN?</span><br />
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">7th-8th Feb 2025, 10 am onwards</span>
              </v-flex>
            </v-layout>
            <br />
            <v-layout wrap justify-center pt-4>
              <v-flex xs12>
                <span style="color: black; font-weight: bold; font-size: 20px" class="poppinsbold">QUESTIONS?</span><br />
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">Call on
                  <b> <span class="poppinsbold">+91 7069074843</span> </b></span>
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">Email at</span>
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">
                  <b>
                    <span class="poppinsbold">saymanti@wti.org.in</span></b></span>
              </v-flex>
              <v-flex xs12 pt-5>
                <v-btn color="black" dark href="Agenda.pdf" target="_blank"><span
                    style="text-transform: none; font-family: poppinsmedium; font-size: 14px; color:white">View Agenda</span></v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center py-5 pa-2 class="hidden-sm-and-down">
          <v-flex xs12 sm4 md4 style="background-color: #9aa32f" pb-5>
            <v-img :src="require('./../../assets/images/Gau.png')" contain height="300px"><template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                </v-row> </template></v-img>
            <span style="color: black; font-weight: bold; font-size: 22px" class="poppinsbold">WHERE?</span><br />
            <v-layout wrap justify-center pt-4 pb-9>
              <v-flex xs12 sm7 md9>
                <span style="color: black; font-size: 20px" class="poppinsregular">Jayadev Bhavan,Unit-2,
                </span>
                <br />
                <span style="color: black; font-size: 16px" class="poppinsregular">Ashok Nagar, Bhubaneswar,</span>
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">
                  Odisha-751001</span>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-5>
              <v-flex xs12>
                <span style="color: black; font-weight: bold; font-size: 22px" class="poppinsbold">WHEN?</span><br />
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">18th October 2023</span>
              </v-flex>
            </v-layout>
            <br />
            <v-layout wrap justify-center pt-4>
              <v-flex xs12>
                <span style="color: black; font-weight: bold; font-size: 20px" class="poppinsbold">QUESTIONS?</span><br />
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">Call on
                  <b> <span class="poppinsbold">+91 7069074843</span> </b></span>
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">Email at</span>
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">
                  <b>
                    <span class="poppinsbold">oic.campaigns@wti.org.in</span></b></span>
              </v-flex>
             
            </v-layout>
          </v-flex>
          <v-flex xs12 sm8 md8 text-center>
            <v-layout wrap justify-center>
              <v-flex xs12 sm8 text-center>
                <v-img :src="require('./../../assets/images/GajaUtsavOdishalogo.png')" contain><template
                    v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                    </v-row> </template></v-img>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pa-5>
              <v-flex xs12 sm10 text-left pt-5>
                <span style="color: black; font-size: 16px" class="poppinsregular">
                  Odisha, with its rich biodiversity and remarkable elephant
                  population, home to over 1900 elephants across three elephant
                  reserves and featuring 12 identified elephant corridors, plays
                  an instrumental role in the conservation of these majestic
                  creatures.<br> <br> On the 12th of August, World Elephant Day, the
                  Odisha Forest Department unveiled its plans to launch the Gaj
                  Utsav campaign in the state. The campaign aims to secure the
                  Right of Passage for the magnificent Asian elephants in India.
                  In recent years, the Asian elephant’s presence has become
                  synonymous with conflicts and threats due to anthropogenic
                  pressures and habitat degradation. The Gaj Utsav Campaign, led
                  by the Wildlife Trust of India (WTI) in collaboration with the
                  Odisha Forest Department strives to address these challenges
                  and create a positive future for these iconic creatures.
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center py-5 class="hidden-md-and-up">
          <v-flex xs12 sm12 md8 text-center>
            <v-layout wrap justify-center>
              <v-flex xs12 sm8 text-center>
                <v-img :src="require('./../../assets/images/GajaUtsavOdishalogo.png')" contain><template
                    v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                    </v-row> </template></v-img>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pa-5>
              <v-flex xs12 sm10 text-center pt-5>
                <span style="color: black; font-size: 16px" class="poppinsregular">
                  Odisha, with its rich biodiversity and remarkable elephant
                  population, home to over 1900 elephants across three elephant
                  reserves and featuring 12 identified elephant corridors, plays
                  an instrumental role in the conservation of these majestic
                  creatures. <br> On the 12th of August, World Elephant Day, the
                  Odisha Forest Department unveiled its plans to launch the Gaj
                  Utsav campaign in the state. The campaign aims to secure the
                  Right of Passage for the magnificent Asian elephants in India.
                  In recent years, the Asian elephant’s presence has become
                  synonymous with conflicts and threats due to anthropogenic
                  pressures and habitat degradation. The Gaj Utsav Campaign, led
                  by the Wildlife Trust of India (WTI) in collaboration with the
                  Odisha Forest Department strives to address these challenges
                  and create a positive future for these iconic creatures.
                </span>
                <!-- <br />
                <br />
                <span>
                  <a href="data.pdf" download="Aana_Handbook_Schedule">
                    <span style="color: #03a5fc">
                      <b><u>Event Schedule</u></b></span
                    >
                  </a>
                </span> -->
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs11 sm11 md4 style="background-color: #9aa32f" pb-5>
            <v-img :src="require('./../../assets/images/Gau.png')" contain height="200px"><template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="#527E33"></v-progress-circular>
                </v-row> </template></v-img>
            <span style="color: black; font-weight: bold; font-size: 20px" class="poppinsbold">WHERE?</span><br />
            <v-layout wrap justify-center pt-4 pb-9>
              <v-flex xs8 sm7 md8>
                <span style="color: black; font-size: 20px" class="poppinsregular"> Jayadev Bhavan,Unit-2,
                </span>
                <br />
                <span style="color: black; font-size: 16px" class="poppinsregular">Ashok Nagar, Bhubaneswar,</span>
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">
                  Odisha-751001</span>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-4>
              <v-flex xs12>
                <span style="color: black; font-weight: bold; font-size: 20px" class="poppinsbold">WHEN?</span><br />
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">18th October 2023</span>
              </v-flex>
            </v-layout>
            <br />
            <v-layout wrap justify-center pt-4>
              <v-flex xs12>
                <span style="color: black; font-weight: bold; font-size: 20px" class="poppinsbold">QUESTIONS?</span><br />
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">Call on
                  <b> <span class="poppinsbold">+91 7069074843</span> </b></span>
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">Email at</span>
                <br />
                <span style="color: black; font-size: 20px" class="poppinsregular">
                  <b>
                    <span class="poppinsbold">oic.campaigns@wti.org.in</span></b></span>
              </v-flex>
              
            </v-layout>
          </v-flex>
        </v-layout>

     
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // pdfLink: require("@/assets/data.pdf"),
    };
  },
};
</script>